import React, { useRef, useState, useEffect } from 'react';
import Webcam from "react-webcam";
import { blobToBase64 } from '../../../Helpers/helpers';
import { Alert, Accordion, AccordionSummary, AccordionDetails, Typography, Button, CircularProgress } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { postCandidateMediaAnswer } from '../../../Helpers/AsyncCalls';
import LoadingButton from '@mui/lab/LoadingButton';

function RecordingQue({ getCallFromRecComponent, question, savePreviousRecAnswers, questionNo, blobs, assessmentType, isPlaying, stopPlaying, getIsVideoRecording }) {
    const webcamRef = useRef(null);
    const mediaRecorderRef = useRef(null);

    const [capturing, setCapturing] = useState(false);
    const [recordedChunks, setRecordedChunks] = useState([]);
    const [recordedVideos, setRecordedVideos] = useState([]);
    const [selectedVideo, setSelectedVideo] = useState(null);
    const [expanded, setExpanded] = useState(false);
    const [recordingTime, setRecordingTime] = useState(0);
    const maxRecordingTime = 120; //Maximum time to capture video length (seconds)
    const numOfMaxAttempts = ((question.numOfMaxAttempts === null || question.numOfMaxAttempts === 0 || question.numOfMaxAttempts === 1) ? 1 : question.numOfMaxAttempts)
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setSelectedVideo(null)
    }, [questionNo])
    useEffect(() => {
        let timer = null;

        if (capturing) {
            timer = setInterval(() => {
                setRecordingTime((prevTime) => prevTime + 1);
            }, 1000);
        } else {
            clearInterval(timer);
        }

        if (recordingTime >= maxRecordingTime) {
            handleStopCaptureClick();
        }

        return () => clearInterval(timer);
    }, [capturing, recordingTime]);

    useEffect(() => {
        if (blobs?.length > 0) {
            setRecordedVideos(blobs);
        }
        else {
            setRecordedVideos([]);
        }
    }, [blobs])
    useEffect(() => {
        if (isPlaying) {
            handleStartCaptureClick();
            stopCapturingTimeout();
        }

    }, [isPlaying]);


    useEffect(() => {
        if (recordedChunks.length > 0 && !capturing && assessmentType === "audio") {
            handleVideoAdd();

        }
    }, [recordedChunks])


    useEffect(() => {
        if (recordedVideos.length > 0 && assessmentType === "audio") {
            handleUpload(recordedVideos[0]);
        }
    }, [recordedVideos])

    //Function to concat the previous video chunks 
    const handleDataAvailable = ({ data }) => {
        if (data.size > 0) {
            setRecordedChunks((prev) => prev.concat(data));
        }
    };

    //Function to start Capturing
    const handleStartCaptureClick = () => {
        setCapturing(true);
        mediaRecorderRef.current = new MediaRecorder(webcamRef.current.stream, {
            mimeType: "video/webm",
        });
        mediaRecorderRef.current.addEventListener(
            "dataavailable",
            handleDataAvailable
        );
        getIsVideoRecording(true);
        mediaRecorderRef.current.start();
    };

    //Function to Stop Capturing
    const handleStopCaptureClick = () => {
        setLoading(true);
        mediaRecorderRef.current.stop();
        setRecordedChunks([]);
        setCapturing(false);
        setRecordingTime(0);
        getIsVideoRecording(false);
        setLoading(false);
    };

    const handleVideoAdd = () => {
        if (recordedChunks.length) {
            const blob = new Blob(recordedChunks, {
                type: "video/webm",
            });
            const formData = new FormData();
            formData.append("video", blob, "react-webcam-stream-capture.webm");
            savePreviousRecAnswers(URL.createObjectURL(formData.get('video')), questionNo)
        }
        setRecordedChunks([]);
    };

    const handleVideoSelect = async (videoUrl) => {
        setSelectedVideo(videoUrl);
        setExpanded(false)
    };

    const handleUpload = async (currSelectedVideo) => {

        setLoading(true);
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
        const response = await fetch(currSelectedVideo);
        const blob = await response.blob();
        const base64String = await blobToBase64(blob);
        try {
            const res = await postCandidateMediaAnswer(null, base64String);
            getCallFromRecComponent(res.id)
        } catch (error) {
            console.error("Error sending video to API:", error);
        }
        stopPlaying();
        setLoading(false);
    };


    const handleAccordionChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const videoConstraints = {
        width: 420,
        height: 420,
        facingMode: "user",
    };


    const stopCapturingTimeout = () => {
        const captureTimeout = setTimeout(async () => {
            await handleStopCaptureClick();
        }, 2 * 60 * 1000);

        return () => clearTimeout(captureTimeout);
    }

    return (
        <div>
            {loading ? <CircularProgress /> : <>
                {recordedVideos.length < numOfMaxAttempts ? (
                    <div className="rec-container">
                        <Webcam
                            height={400}
                            width={400}
                            muted={true}
                            audio={true}
                            mirrored={true}
                            ref={webcamRef}
                            videoConstraints={videoConstraints}
                        />
                        <div className='record-vid-btn'>
                            {capturing ? <button onClick={handleStopCaptureClick}>Stop Capture</button> : (!capturing && recordedChunks.length > 0 && assessmentType !== "audio") ? <button onClick={handleVideoAdd}>Save</button> : <button onClick={handleStartCaptureClick}>Start Capture</button>}
                            {capturing && recordingTime < maxRecordingTime && (
                                <div>
                                    <p>Recording time remaining: {maxRecordingTime - recordingTime} seconds</p>
                                </div>
                            )}
                        </div>
                    </div>
                ) : (
                    <Alert severity="error">Please select a video from below </Alert>
                )}
                {selectedVideo && (
                    <div className="selected-video">
                        <h2>Selected Video:</h2>
                        <div>
                            <video controls src={selectedVideo} />
                            <Button onClick={handleUpload(selectedVideo)}>Save Selected Video</Button>
                        </div>
                    </div>
                )}
                {assessmentType !== "audio" && recordedVideos.length > 0 && (
                    <div>
                        <h2>Recorded Videos:</h2>
                        {recordedVideos.map((videoUrl, index) => (
                            <Accordion
                                key={index}
                                expanded={expanded === `panel${index}`}
                                onChange={handleAccordionChange(`panel${index}`)}
                            >
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls={`panel${index}d-content`}
                                    id={`panel${index}d-header`}
                                >
                                    <Typography>Video {index + 1}</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <div className='single-rec-vid'>
                                        <video controls width="320" height="240" src={videoUrl} />
                                        <Button onClick={() => handleVideoSelect(videoUrl)}>Select</Button>
                                    </div>
                                </AccordionDetails>
                            </Accordion>
                        ))}
                    </div>
                )}




            </>
            }



        </div>
    );
}

export default RecordingQue;
