import React, { useEffect, useState } from 'react'
import Questionnaire from '../components/Questionnaire/Questionnaire';
import StartTest from '../components/StartTest/StartTest';
import { useNavigate, useParams } from 'react-router-dom';
import ResultBox from '../components/Result/ResultBox';
import { getQuestionnaire, getUser, getCandidateResults, getAssessmentData, getCandidateAudioAnswers } from '../Helpers/AsyncCalls'
import { CircularProgress } from '@mui/material';

const Home = ({ assessmentType }) => {
  const navigate = useNavigate();
  const params = useParams();

  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState();
  const [questionnaireData, setQuestionnaireData] = useState([]);
  const [isStarted, setIsStarted] = useState(false);
  const [candidateResults, setCandidateResults] = useState();
  const [assessmentDetails, setAssessmentDetails] = useState();

  useEffect(() => {
    const fetchData = async () => {
      if (Object.keys(params).length !== 2) {
        navigate('/404');
        return;
      }

      try {
        const [userData, assessmentData] = await Promise.all([
          getUser(params.userId),
          getAssessmentData(params.questionnaireId)
        ]);

        setUser(userData);
        setAssessmentDetails(assessmentData);

        if (assessmentType === "audio") {
          setCandidateResults({
            "score": null,
            "countOfTimesMouseOutOfTestWindow": 0,
            "hasCandidateExitedTheFullScreen": false
          });

          if (userData?.id && assessmentData?.id) {
            const audioAnswers = await getCandidateAudioAnswers(userData.id, assessmentData.id);
            if (audioAnswers.count > 0) {
              navigate('/endtest');
              return;
            }
          }
        } else {
          const results = await getCandidateResults(params.userId, params.questionnaireId);
          setCandidateResults(results);
        }

        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        navigate('/404');
      }
    };

    fetchData();
  }, [params, assessmentType, navigate]);

  useEffect(() => {
    if (isStarted && questionnaireData.length <= 0 && assessmentDetails?.id) {
      getQuestionnaire(assessmentDetails.id)
        .then(data => setQuestionnaireData(data))
        .catch(error => {
          console.error("Error fetching questionnaire:", error);
          navigate('/404');
        });
    }
  }, [isStarted, questionnaireData.length, assessmentDetails, navigate]);

  if (loading) {
    return (
      <div className="h-screen flex items-center justify-center">
        <CircularProgress />
      </div>
    );
  }

  if (candidateResults === undefined || candidateResults === null || candidateResults.score === null) {
    return isStarted ? (
      <Questionnaire
        user={user}
        questionnaireData={questionnaireData}
        setCandidateResults={setCandidateResults}
        assessmentDetails={assessmentDetails}
        assessmentType={assessmentType}
      />
    ) : (
      <StartTest
        user={user}
        setIsStarted={setIsStarted}
        loading={loading}
        assessmentDetails={assessmentDetails}
        assessmentType={assessmentType}
      />
    );
  }

  return (
    <ResultBox
      user={user}
      score={candidateResults?.score}
      totalQuestions={assessmentDetails?.numOfQuestions}
    />
  );
};

export default Home;