import { Container } from '@mui/material'
import React from 'react'
import './ResultBox.css'

function AudioResultBox() {
    return (
        <div>
            <Container maxWidth="xl" sx={{ display: 'flex', height: "100vh", justifyContent: "center", alignItems: "center" }}>
                <div className="bg-white scoreCard p-3 md:p-7 shadow-lg scoreCard rounded-md">
                    <div className='mb-7'>
                        <h4 className='text-4xl roboto text-gray-600 mb-3'>Thanks for your time</h4>
                        <h5 className='text-xs font-bold text-green-500'>Dear Candidate, your test has been successfully submitted.
                            <span><img className='w-8 mr-3 inline-block' src='https://cdn-icons-png.flaticon.com/512/8968/8968524.png' alt="" /></span>
                            <br />
                        </h5>
                    </div>
                    <div className='text-left mb-5 text-xs roboto flex flex-col md:flex-row md:justify-between ml-2 md:ml-1'>
                        {/* <p>Name: <span className='ml-2'>{user?.firstName + ' ' + user?.lastName}</span></p>
                        <p>Email: <span className='ml-2'>{user?.email}</span></p> */}
                    </div>

                    <div className="Test-detail flex flex-col  p-4 rounded-lg font-semibold">
                        <div className="--top flex  justify-center w-full pb-4 items-center">
                            We'll reach out to you once we've evaluated all your responses on the assessment .
                        </div>

                    </div>
                </div>
            </Container>
        </div>
    )
}

export default AudioResultBox
