export const getTotalScore = (scoreObj) => {
    return Object.keys(scoreObj).reduce((totalScore,key)=>{
        totalScore += scoreObj[key]
        return totalScore
    },0);
}
export const server = {
    URL:{
        local:"http://localhost:8080",
        production:"https://quiz-app-server-5we2.onrender.com"
    },
    OurUrl: "https://entntcareersapi.azurewebsites.net"
}

export async function blobToBase64(blob) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        const base64String = reader.result.split(',')[1];
        resolve(base64String);
      };
      reader.onerror = () => {
        reader.abort();
        reject(new Error('Error converting blob to Base64'));
      };
      reader.readAsDataURL(blob);
    });
  }